import React, { Component } from "react"
import PropTypes from "prop-types"

import BoutonRetour from "../boutons/BoutonRetour"
import { locales } from "../../../locales"

export default class BarreActions extends Component {
  constructor(props) {
    super(props)
    this.langue = props.langue
  }

  render() {
    return (
      <div>
        <div id="boutique-barre-actions">
          <BoutonRetour
            langue={this.langue}
            texte={locales[this.langue].site.retour}
          />
          <div className="boutique-barre-actions-conteneur-panier">
            <button className="snipcart-checkout">
              <svg
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M51.714 20.47L55 60H9l3.286-39.53h9.857v-6.588C22.143 8.424 26.556 4 32 4c5.444 0 9.857 4.424 9.857 9.882v6.589h9.857zM25.43 13.883v16.47h-3.286v-6.587h-6.834l-2.737 32.94h38.856l-2.737-32.94h-6.834v6.588h-3.286v-16.47c0-3.634-2.947-6.589-6.571-6.589-3.624 0-6.571 2.955-6.571 6.588zm3.285 9.883V20.47h6.572v3.294h-6.572z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    )
  }
}

BarreActions.propTypes = {
  codePageRetour: PropTypes.string,
  texteBoutonRetour: PropTypes.string,
  langue: PropTypes.string,
}
