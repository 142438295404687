import { objetEstDefini, supprimerDernierCaractere } from "./utils"
import { locales } from "../../../locales"

export function chargerLibrairiesSnipcart() {
  if (!objetEstDefini(window.Snipcart)) {
    const css = document.createElement("link")
    css.href = "https://cdn.snipcart.com/themes/v3.1.1/default/snipcart.css"
    css.rel = "stylesheet"
    document.head.appendChild(css)

    const script = document.createElement("script")
    script.src = "https://cdn.snipcart.com/themes/v3.1.1/default/snipcart.js"
    script.async = true
    document.body.appendChild(script)

    document.addEventListener("snipcart.ready", () => {
      window.Snipcart.api.session.setLanguage("fr", {
        actions: {
          continue_shopping: "Revenir au site",
          checkout: "Livraison et paiement",
        },
        header: {
          title_cart_summary: "Votre panier",
        },
        cart: {
          summary: "Votre commande",
        },
        address_form: {
          address1: "Adresse postale",
          address2: "Complément d'adresse",
          province: "Département, région ou province",
        },
        payment: {
          form: {
            card_label:
              "Numéro de carte de crédit, date d’expiration (MM/AA) et code de sécurité (CVV) (les 3 chiffres au dos de la carte)",
          },
        },
      })
    })
  }
}

export function decorerBoutonAcheterAvecAttributsSnipcart(
  image,
  langue,
  prestations,
  finition
) {
  let attributs = {
    "data-item-id": image.code,
    "data-item-name": image[langue].titre,
    "data-item-url": image[langue].tirage.url_page,
    "data-item-price": image[langue].tirage.prix_minimal,
    "data-item-description": image[langue].description,
    "data-item-image": image[langue].tirage.url_miniature,
    "data-item-custom1-name": locales[langue].boutique.finition,
    "data-item-custom1-options": construireOptionsItemSnipcart(prestations),
    "data-item-custom1-value": obtenirFinitionSelectionnee(finition),
  }

  return attributs
}

function obtenirFinitionSelectionnee(finition) {
  if (objetEstDefini(finition)) return finition.code
  else return ""
}

export function classeCSSSnipcartAjoutItemAuPanier() {
  return "snipcart-add-item"
}

export function construireOptionsItemSnipcart(prestationsTirage) {
  let options = ""
  for (let dimension in prestationsTirage.finitions)
    options += obtenirOptionsSnipcartPourUneDimension(
      prestationsTirage.finitions[dimension]
    )
  return supprimerDernierCaractere(options)
}

function obtenirOptionsSnipcartPourUneDimension(tarifsDimension) {
  let options = ""
  for (let i = 0; i < tarifsDimension.length; i++) {
    options += obtenirOptionSnipcart(tarifsDimension[i]) + "|"
  }
  return options
}

function obtenirOptionSnipcart(tarif) {
  return `${tarif.code}${obtenirSupplementOption(
    tarif.differenceAvecPrixMinimal
  )}`
}

function obtenirSupplementOption(differenceAvecPrixMinimal) {
  if (differenceAvecPrixMinimal === 0) return ""
  else return `[+${differenceAvecPrixMinimal}]`
}
