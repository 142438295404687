import React, { Component } from "react"
import PropTypes from "prop-types"
import BarreActions from "../../boutique/BarreActions"
import LayoutFR from "./LayoutFR"

import { chargerLibrairiesSnipcart } from "../../../js/client-es/snipcart"

export default class LayoutBoutique extends Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    return (
      <LayoutFR page={this.props.page}>
        <BarreActions langue={this.props.page.langue} />
        {this.props.children}
      </LayoutFR>
    )
  }

  componentDidMount() {
    chargerLibrairiesSnipcart()
  }
}

LayoutBoutique.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  page: PropTypes.object,
}
