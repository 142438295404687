import React from "react"
import PropTypes from "prop-types"

import Lien from "../navigation/Lien"
import { chaineNulleOuVide } from "../../js/client-es/utils"

import { locales } from "../../../locales"

export default function BoutonRetour({
  classeCSS = "bouton-retour-conteneur",
  codePageCible = "",
  ancre = "",
  texte = "",
  langue = "fr",
}) {
  if (chaineNulleOuVide(codePageCible)) {
    return (
      <div className={classeCSS}>
        <a
          aria-label="Fermer / Close"
          href="#"
          onClick={e => {
            e.preventDefault()
            history.back()
          }}
          className={classeCSS}
        >
          <div className="bouton-retour-icone">{svgRetour()}</div>
          <div className="bouton-retour-texte">
            {obtenirTexteRetour(texte, langue)}
          </div>
        </a>
      </div>
    )
  } else {
    return (
      <Lien codePageCible={codePageCible} ancre={ancre}>
        <div className={classeCSS}>
          <div className="bouton-retour-icone">{svgRetour()}</div>
          <div className="bouton-retour-texte">
            {obtenirTexteRetour(texte, langue)}
          </div>
        </div>
      </Lien>
    )
  }
}

function obtenirTexteRetour(texteEnvoyeEnParametres, langue) {
  if (chaineNulleOuVide(texteEnvoyeEnParametres))
    return locales[langue].site.retour
  else return texteEnvoyeEnParametres
}

function svgRetour() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path d="M12.636 30.158H58v3.423H12.372l9.148 9.055L19.132 45 6 32l13.132-13 2.388 2.364-8.884 8.794z" />
    </svg>
  )
}

BoutonRetour.propTypes = {
  classeCSS: PropTypes.string,
  texte: PropTypes.string,
  fonctionRetour: PropTypes.func,
  langue: PropTypes.string,
}
